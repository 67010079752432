import React from "react";
import { Col, Container, Row } from "reactstrap";
import { ImageBlock } from "../components/images/Images";
import { Service, ServiceText } from "../components/service/Service";
import { TextBlock, TitleH2 } from "../components/textBlock/TextBlock";
import ThumbImg2 from "../images/gfx/b.png";
import { Section } from "../layout/section/Section";

const SecuritySection = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="flex-row-reverse align-items-center justify-content-between g-gs">
          <Col lg="5">
            <ImageBlock className="img-block-s1 right">
              <img src={ThumbImg2} alt="Thumb img" />
            </ImageBlock>
          </Col>
          <Col lg="6">
            <TextBlock>
              <TitleH2>Transparency. Security. Simplicity.</TitleH2>
              <div className="g-gs">
                <Service className="service-s3">
                  <ServiceText>
                    <p>
                      At INCWT.com, we’re here to make cryptocurrency exchanges
                      seamless, secure, and compliant. Whether you’re trading
                      online or in-person, our licensed platform ensures that
                      you transact with confidence.
                    </p>
                  </ServiceText>
                </Service>
              </div>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default SecuritySection;
