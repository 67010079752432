import React from "react";
import { Col, Container, Row } from "reactstrap";
import { ImageBlock } from "../components/images/Images";
import { Review, ReviewContent, ReviewText } from "../components/review/Review";
import { TextBlock, TitleH2 } from "../components/textBlock/TextBlock";
import ThumbImg1 from "../images/gfx/a.png";
import { Section } from "../layout/section/Section";

const CommitmentSection = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="align-items-center justify-content-lg-between g-gs">
          <Col lg="5">
            <ImageBlock className="img-block-s1 left">
              <img src={ThumbImg1} alt="Thumb img" />
            </ImageBlock>
          </Col>
          <Col lg="6">
            <TextBlock className="me-xl-5">
              <TitleH2>Our Commitment to Compliance</TitleH2>
              <h4>
                At incwt.com, compliance is not just a requirement—it’s our
                priority. We:
              </h4>
              <Review>
                <ReviewContent>
                  <ReviewText>
                    <ul className="list list-lg list-purple list-checked-circle gy-4">
                      <li className="ps-5">
                        Fully comply with anti-money laundering (AML) and
                        counter-terrorism financing (CTF) regulations.
                      </li>
                      <li className="ps-5">
                        Conduct thorough KYC verification for all users,
                        leveraging an EU-licensed provider.
                      </li>
                      <li className="ps-5">
                        Screen all transactions against international sanctions,
                        politically-exposed persons and other high-risk customer
                        screening lists.
                      </li>
                    </ul>
                  </ReviewText>
                </ReviewContent>
              </Review>
              <p className="note-text">
                <span className="blue-text">Please note:</span> We do not
                provide services to residents of high-risk countries as per
                Lithuanian and international regulations, nor do we service U.S.
                residents.
              </p>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default CommitmentSection;
