const Vision = (props) =>{
    return(
        <svg fill="currentColor" viewBox="0 0 512 512">
            <path d="M436.6 175c-26.7-22.5-53.8-40.5-80.7-53.3-33.9-16.3-67.5-24.5-99.9-24.5s-66 8.2-99.9 24.5c-26.9 12.9-54.1 30.8-80.7 53.3-45.3 38.2-73 75.8-74.2 77.4-1.6 2.1-1.6 5.1 0 7.2 1.2 1.6 28.9 39.2 74.2 77.4 26.7 22.5 53.8 40.5 80.7 53.3 33.9 16.2 67.5 24.5 99.9 24.5s66-8.2 99.9-24.5c26.9-12.9 54.1-30.8 80.7-53.3 45.3-38.2 73-75.8 74.2-77.4 1.6-2.1 1.6-5.1 0-7.2-1.1-1.6-28.9-39.2-74.2-77.4zm-7.9 152.7c-25.9 21.8-52.1 39.2-78.1 51.7-32.2 15.4-64.1 23.3-94.6 23.3s-62.3-7.8-94.5-23.2c-26-12.4-52.2-29.8-78-51.6C58 306.3 34.6 282.2 13.8 256c20.7-26.1 44-50.1 69.5-71.7 25.9-21.8 52.1-39.2 78.1-51.7 32.2-15.4 64.1-23.3 94.6-23.3s62.3 7.8 94.5 23.2c26 12.4 52.2 29.8 78 51.6 25.5 21.6 48.9 45.7 69.7 71.9-20.7 26.1-44 50.1-69.5 71.7zM249.9 20.1v54.6c0 3.4 2.7 6.1 6.1 6.1 3.4 0 6.1-2.7 6.1-6.1V20.1c0-3.4-2.7-6.1-6.1-6.1-3.4 0-6.1 2.7-6.1 6.1zm194.4 53.8l-42.8 42.6c-2.4 2.4-2.4 6.2 0 8.6 2.4 2.4 6.3 2.4 8.7 0L453 82.5c2.4-2.4 2.3-6.3-.1-8.6-2.4-2.4-6.2-2.4-8.6 0zm-342.5 51.2c2.4 2.4 6.3 2.4 8.7 0s2.4-6.2 0-8.6L67.7 73.9c-2.4-2.4-6.3-2.3-8.7.1-2.3 2.4-2.3 6.2 0 8.6l42.8 42.5zM256 431.2c-3.4 0-6.1 2.7-6.1 6.1v54.6c0 3.4 2.7 6.1 6.1 6.1 3.4 0 6.1-2.7 6.1-6.1v-54.6c0-3.4-2.7-6.1-6.1-6.1zm-154.2-44.3L59 429.5c-2.4 2.4-2.3 6.3.1 8.6s6.2 2.3 8.6 0l42.8-42.6c2.4-2.4 2.4-6.2 0-8.6-2.5-2.4-6.3-2.4-8.7 0zm308.4 0c-2.4-2.4-6.3-2.4-8.7 0-2.4 2.4-2.4 6.2 0 8.6l42.8 42.6c2.4 2.4 6.3 2.3 8.7-.1 2.3-2.4 2.3-6.2 0-8.6l-42.8-42.5z"></path>
            <path d="M74.3 208.5c-14.1 13.2-27.5 27.1-40.1 41.8-2.2 2.6-1.9 6.4.7 8.6 2.6 2.2 6.4 1.9 8.6-.7 12.3-14.3 25.3-27.9 39.1-40.8 2.5-2.3 2.7-6.1.4-8.6s-6.1-2.7-8.7-.3c.1-.1.1 0 0 0zm19.5-17.2c-1.2 1-2.4 2-3.6 3.1-2.6 2.2-2.9 6-.7 8.6 2.2 2.6 6.1 2.9 8.6.7 1.2-1 2.3-2 3.5-3 2.6-2.2 2.9-6 .8-8.6-2.1-2.6-6-2.9-8.6-.8zm165.5-78c-30.8-.7-61.1 8.5-86.2 26.4-2.8 1.9-3.4 5.7-1.5 8.5s5.8 3.4 8.5 1.5l.1-.1c23.1-16.5 51.1-24.9 79.5-24.1 68.9 1.9 124.9 57.4 127.2 126.1 2.5 74.4-57.9 135.7-132.2 135-70.7-.7-128.5-58-129.6-128.4-.5-28.8 8.6-56.9 25.9-80 1.8-2.4 1.6-5.8-.6-8-2.4-2.4-6.3-2.4-8.7 0-.2.2-.4.4-.6.7-19.4 25.9-29.4 57.7-28.2 90.1 2.5 75.3 64.3 136.2 139.9 137.8 81.5 1.8 148.2-64.7 146.4-145.9-1.7-76.1-63.7-137.9-139.9-139.6z"></path>
            <path d="M256.6 308c29.8 0 53.9-24.1 53.9-53.7 0-29.7-24.1-53.7-53.9-53.7-29.8 0-53.9 24.1-53.9 53.7s24.1 53.7 53.9 53.7zm0-95.3c23 0 41.7 18.6 41.7 41.5s-18.7 41.5-41.7 41.5c-23 0-41.7-18.6-41.7-41.5.1-22.9 18.7-41.4 41.7-41.5z"></path>
            <path d="M256.6 277.1c12.6 0 22.9-10.2 22.9-22.8 0-12.6-10.3-22.8-22.9-22.8s-22.9 10.2-22.9 22.8c0 12.6 10.2 22.8 22.9 22.8zm0-33.5c5.9 0 10.7 4.8 10.7 10.6 0 5.9-4.8 10.6-10.7 10.6-5.9 0-10.7-4.8-10.7-10.6s4.8-10.6 10.7-10.6z"></path>
            <path d="M160 272.6l19.9 3.2c1.7 6 4.1 11.9 7.1 17.3l-12 16.4c-1.8 2.4-1.5 5.8.6 7.9l17.5 17.5c2.1 2.1 5.5 2.4 7.9.6l16.4-11.9c5.5 3.1 11.3 5.5 17.4 7.2l3.2 19.8c.5 2.9 3.1 5.1 6 5h25c3 .1 5.6-2.1 6-5l3.2-19.9c6.1-1.7 11.9-4.1 17.4-7.1l16.4 11.9c2.4 1.8 5.8 1.5 7.9-.6l17.5-17.5c2.1-2.1 2.4-5.5.6-7.9l-11.9-16.4c3.1-5.5 5.5-11.3 7.2-17.3l20.3-3.2c3-.5 5.3-3 5.4-6v-24.7c-.1-3-2.4-5.6-5.4-6l-20.2-3.2c-1.7-6-4.2-11.9-7.3-17.3L338 199c1.8-2.4 1.5-5.8-.6-7.9l-17.5-17.5c-2.1-2.1-5.5-2.4-7.9-.6l-16.4 11.9c-5.5-3.1-11.3-5.5-17.4-7.2l-3.2-20.1c-.5-3-3-5.2-6-5.3h-24.8c-3 .1-5.6 2.3-6 5.3l-3.2 20.1c-6.1 1.7-11.9 4.1-17.4 7.2L201.1 173c-2.4-1.8-5.8-1.5-7.9.6l-17.5 17.5c-2.1 2.1-2.4 5.5-.6 7.9l11.9 16.4c-3.1 5.5-5.5 11.3-7.2 17.3l-19.8 3.2c-2.9.5-5 3.1-4.9 6v24.7c-.2 3 1.9 5.5 4.9 6zm7.3-25.5l18.7-3c2.5-.4 4.4-2.3 4.9-4.7 1.7-7.5 4.6-14.6 8.7-21.1 1.3-2.1 1.2-4.8-.2-6.8L188.1 196l10.1-10.1 15.5 11.2c2 1.5 4.7 1.6 6.9.2 6.5-4.1 13.7-7.1 21.2-8.8 2.5-.6 4.3-2.6 4.7-5.1l3-19h14.3l3 19c.4 2.5 2.2 4.5 4.7 5.1 7.5 1.7 14.7 4.7 21.2 8.8 2.1 1.3 4.8 1.3 6.9-.2l15.5-11.2 10.1 10.1-11.2 15.4c-1.5 2-1.6 4.7-.2 6.8 4.1 6.5 7.1 13.6 8.8 21.1.6 2.5 2.7 4.3 5.2 4.7l19.1 3v14.3l-19.1 3c-2.5.4-4.5 2.2-5.1 4.7-1.7 7.5-4.7 14.6-8.8 21.1-1.3 2.1-1.3 4.8.2 6.8l11.2 15.4-10.3 10.3-15.5-11.2c-2-1.5-4.8-1.6-6.9-.2-6.5 4.1-13.7 7.1-21.2 8.8-2.4.5-4.3 2.4-4.7 4.8l-3 18.7h-14.3l-3-18.7c-.4-2.5-2.3-4.4-4.7-4.9-7.5-1.7-14.7-4.6-21.2-8.7-2.1-1.3-4.8-1.2-6.9.2l-15.5 11.2-10.1-10.1 11.2-15.4c1.5-2 1.6-4.7.2-6.8-4.1-6.5-7.1-13.6-8.8-21.1-.5-2.4-2.4-4.3-4.8-4.7l-18.7-3 .2-14.4z"></path>
        </svg>
    )
}

export default Vision
