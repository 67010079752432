import { useContext } from "react";
import LineChart from "../LineChart/LineChart";
import { AppContext } from "../../context/AppContext";

export const AssetLogo = ({ asset }) => {
  return (
    <div className="coin-icon">
      <img
        className="icon"
        src={`/assets/icons/${asset.icon}.svg`}
        alt={asset.name}
      />
    </div>
  );
};

export const PairLogo = ({ pair, hideTo }) => {
  return (
    <ul className="icon-overlap">
      <li>
        <img
          className="icon icon-circle "
          src={`/assets/icons/${pair.base.icon}.svg`}
          alt={pair.base.name}
        />
      </li>
      <li>
        <img
          className="icon icon-circle"
          src={`/assets/icons/${pair.quote.icon}.svg`}
          alt={pair.base.name}
        />
      </li>
    </ul>
  );
};

export const PairItem = ({ pair, trend }) => {
  const { quotes } = useContext(AppContext);
  const buyPrice = quotes.get(`${pair.base.code}/${pair.quote.code}`)?.rate;
  const sellPrice = quotes.get(`${pair.quote.code}/${pair.base.code}`)?.rate;
  const pairPrice = ((buyPrice + sellPrice) / 2).toFixed(3) ;
  return (
    <div className="pair-item">
      <div className="pair-name-wrapper">
        <PairLogo pair={pair} />
        <div className="pair-name">
          {pair.base.code}/{pair.quote.code}
        </div>
      </div>
      <div>
        <div className="pair-price">{pairPrice}</div>
      </div>
      <div className="pair-chart">
        {trend && <LineChart data={trend} color={"Rising"} />}
      </div>
    </div>
  );
};
