import FooterSection from "../../section/FooterSection";
import HeaderSection from "../../section/HeaderSection";

const PageLayout = (props) => {
  return (
    <div className="nk-main">
      <HeaderSection className="header has-header-main-s1 bg-lighter" />
      {props.children}
      <FooterSection className="bg-lighter" />
    </div>    
  );
};
export default PageLayout;
