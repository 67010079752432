import React from "react";
import { Col, Container, Row } from "reactstrap";
import { ImageBlock } from "../components/images/Images";
import {
  Service,
  ServiceIcon,
  ServiceText,
} from "../components/service/Service";
import {
  BarChart,
  Cloud,
  Contents,
  HelpDesk,
  Vision,
} from "../components/svg/Icon";
import { TextBlock, TitleH2 } from "../components/textBlock/TextBlock";
import { Section, SectionContant } from "../layout/section/Section";

const GetStartedSection = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="justify-content-center text-center get-started-title-row">
          <Col xl="6" lg="7" md="8">
            <TextBlock className="is-compact">
              <ImageBlock className="h-150px mb-4">
                <HelpDesk />
              </ImageBlock>
              <TitleH2>How to Get Started</TitleH2>
            </TextBlock>
          </Col>
        </Row>
        <SectionContant>
          <Row className="justify-content-center text-center g-gs">
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <BarChart />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">View Live Rates</h4>
                  <p>
                    Access current cryptocurrency exchange rates anytime on our
                    platform.
                  </p>
                </ServiceText>
              </Service>
            </Col>
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <Contents />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">Register Online</h4>
                  <p>
                    Complete your registration and KYC verification in minutes.
                  </p>
                </ServiceText>
              </Service>
            </Col>
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <Cloud />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">Exchange Securely</h4>
                  <p>If registered, trade directly using your bank account.</p>
                </ServiceText>
              </Service>
            </Col>
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <Vision />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">Visit Us In-Person</h4>
                  <p>
                    Unable to register online? Visit our office for in-person
                    registration and exchange services.
                  </p>
                </ServiceText>
              </Service>
            </Col>
          </Row>
        </SectionContant>
      </Container>
    </Section>
  );
};

export default GetStartedSection;
