import { commService } from "./CommunicationService";

const ResultType = {
    Unknown: 0,
    Success: 1,
    Failure: 2,
    Malformed: 3,
    Exception: 4
};

export default class BaseCommService {
    _id = 0; // request id
    _connecction;
    constructor() {
        this._connecction = commService;
    }

    get hub() {
        throw new Error("Not Implemented");
    }

    async _invokeMethod(method, request, resultProperty = '') {
        try {
            const requestId = method + this._id++;
            console.time(requestId);
            console.log(method, request);
            const response = await this.hub.invoke(method, request)
            if (response.errorDetails !== null) {
                console.timeEnd(requestId);
                throw new Error(response.errorDetails);
            }
            if (response.opResult !== ResultType.Success) {
                console.timeEnd(requestId);
                throw new Error("Invalid response: " + response.opResult);
            }
            console.timeEnd(requestId);
            console.log(response);
            if (resultProperty) {
                return response[resultProperty];
            }
            return response;
        } catch (e) {
            if (e instanceof Error) {
                console.error(e.message);
            }
            throw e;
        }
    }
}