import React from "react";
import { Col, Container, Row } from "reactstrap";
import PageLayout from "../../layout/page-layout/PageLayout";
import { Section, SectionHead } from "../../layout/section/Section";

const TermsOfUse = () => {
  return (
    <PageLayout>
      <Section className="rules-section">
        <Container>
          <Row>
            <Col>
              <SectionHead>
                <h2 className="title">Terms of Use</h2>
              </SectionHead>
              <p>
                <strong>Effective Date:</strong> November 26, 2024
              </p>
              <p>
                Welcome to INCWT.com (“Site”), operated by Labasnamas UAB (“we,”
                “our,” or “us”). These Terms of Use (“Terms”) govern your access
                to and use of our Site and services. By accessing or using our
                Site, you agree to comply with these Terms. If you do not agree,
                you must not use our services.
              </p>

              <h5 className="mt-4">1. Definitions</h5>
              <ul>
                <li>
                  “User” refers to any individual or entity accessing the Site
                  or using our services.
                </li>
                <li>
                  “Services” refer to cryptocurrency exchange, registration, KYC
                  verification, and other related services provided by us.
                </li>
                <li>
                  “KYC” stands for Know Your Customer, a regulatory compliance
                  process requiring identity verification.
                </li>
              </ul>

              <h5 className="mt-4">2. Eligibility</h5>
              <p>To use our services, you must:</p>
              <ul>
                <li>
                  Be at least 18 years old or the legal age of majority in your
                  jurisdiction.
                </li>
                <li>
                  Not be a resident or citizen of a restricted country,
                  including but not limited to countries classified as high-risk
                  under Lithuanian or international regulations, and the United
                  States.
                </li>
                <li>
                  Agree to comply with all applicable laws and regulations,
                  including anti-money laundering (AML) and counter-terrorism
                  financing (CTF) laws.
                </li>
              </ul>
              <p>
                We reserve the right to refuse service to any user at our
                discretion, in addition to mandatory refusal for those flagged
                during KYC, AML, or sanctions screening.
              </p>

              <h5 className="mt-4">3. Services Offered</h5>

              <h6 className="mt-3">3.1 Cryptocurrency Exchange</h6>
              <ul>
                <li>
                  Registered users can exchange cryptocurrencies through their
                  bank accounts on our platform.
                </li>
                <li>
                  In-person exchange services, including cash transactions, are
                  available at our office, subject to Lithuanian law and AML
                  regulations.
                </li>
              </ul>

              <h6 className="mt-3">3.2 Registration and KYC Verification</h6>
              <ul>
                <li>
                  All users must complete the registration process and pass KYC
                  verification through our EU-licensed provider before accessing
                  certain services.
                </li>
                <li>
                  Users who cannot complete online KYC for technical reasons may
                  visit our office for manual registration and verification.
                </li>
              </ul>

              <h5 className="mt-4">4. Account Responsibilities</h5>
              <ul>
                <li>
                  You are responsible for maintaining the confidentiality of
                  your account credentials.
                </li>
                <li>
                  You agree to notify us immediately of any unauthorized access
                  or suspicious activity.
                </li>
                <li>
                  We are not liable for losses resulting from your failure to
                  secure your account.
                </li>
              </ul>

              <h5 className="mt-4">5. Compliance with Laws</h5>
              <p>By using our services, you represent and warrant that:</p>
              <ul>
                <li>
                  All funds and transactions are lawful and comply with
                  applicable AML, CTF, and other regulatory requirements.
                </li>
                <li>
                  You are not engaging in activities intended to evade sanctions
                  or laws.
                </li>
              </ul>

              <h5 className="mt-4">6. Restricted Countries</h5>
              <p>
                We do not provide services to users residing in or associated
                with high-risk countries as defined by Lithuanian law,
                international standards, or our internal policies. These
                countries include, but are not limited to:
              </p>
              <h6 className="mt-3">High-Risk Jurisdictions:</h6>
              <ul>
                <li>Afghanistan</li>
                <li>Belarus</li>
                <li>Central African Republic</li>
                <li>Democratic Republic of the Congo</li>
                <li>Iran</li>
                <li>Iraq</li>
                <li>Libya</li>
                <li>North Korea</li>
                <li>Russian Federation</li>
                <li>Somalia</li>
                <li>South Sudan</li>
                <li>Sudan</li>
                <li>Syria</li>
                <li>Venezuela</li>
                <li>Yemen</li>
              </ul>
              <h6 className="mt-3">United States of America</h6>
              <p>
                Due to specific regulatory requirements, services are not
                extended to U.S. residents or citizens.
              </p>

              <h5 className="mt-4">7. Prohibited Activities</h5>
              <p>You agree not to:</p>
              <ul>
                <li>
                  Use our platform for illegal activities, including money
                  laundering, terrorism financing, or fraud.
                </li>
                <li>
                  Attempt to bypass our security systems or KYC/AML processes.
                </li>
                <li>
                  Engage in transactions involving prohibited goods, services,
                  or jurisdictions.
                </li>
              </ul>
              <p>
                We reserve the right to terminate accounts; suspicious
                activities will be reported to relevant authorities.
              </p>

              <h5 className="mt-4">8. Fees and Pricing</h5>
              <p>
                All fees for services, including exchange rates and transaction
                fees, are transparently displayed on the Site. Fees and rates
                may change without prior notice; any updates will be reflected
                on the Site.
              </p>

              <h5 className="mt-4">9. Intellectual Property</h5>
              <p>
                All content on the Site, including text, graphics, logos, and
                software, is the property of Labasnamas UAB or its licensors.
                You may not reproduce, distribute, or create derivative works
                without prior written consent.
              </p>

              <h5 className="mt-4">10. Limitation of Liability</h5>
              <p>To the fullest extent permitted by law:</p>
              <ul>
                <li>
                  We are not liable for any indirect, incidental, or
                  consequential damages arising from your use of our services.
                </li>
                <li>
                  We make no guarantees regarding the availability, accuracy, or
                  performance of our services.
                </li>
              </ul>

              <h5 className="mt-4">11. Termination</h5>
              <p>We reserve the right to:</p>
              <ul>
                <li>
                  Suspend or terminate your account if you violate these Terms
                  or engage in prohibited activities.
                </li>
                <li>
                  Refuse access to services for any reason, including failure to
                  comply with KYC/AML requirements.
                </li>
              </ul>

              <h5 className="mt-4">12. Privacy and Data Protection</h5>
              <p>
                Your personal data is processed in accordance with our Privacy
                Policy, which forms part of these Terms. By using our services,
                you consent to the collection and processing of your data as
                outlined in the Privacy Policy.
              </p>

              <h5 className="mt-4">13. Changes to the Terms</h5>
              <p>
                We may update these Terms from time to time to reflect changes
                in services or legal requirements. The latest version will be
                posted on this page with the “Effective Date.” Continued use of
                the Site after updates constitutes your acceptance of the
                revised Terms.
              </p>

              <h5 className="mt-4">14. Governing Law and Dispute Resolution</h5>
              <ul>
                <li>These Terms are governed by the laws of Lithuania.</li>
                <li>
                  Any disputes arising from these Terms or our services will be
                  subject to the exclusive jurisdiction of Lithuanian courts.
                </li>
              </ul>

              <h5 className="mt-4">15. Contact Us</h5>
              <p>
                If you have questions or concerns about these Terms, please
                contact us:
              </p>
              <ul>
                <li>
                  Email:{" "}
                  <a href="mailto:privacy@incwt.com">privacy@incwt.com</a>
                </li>
                <li>Phone: +370 690 33744</li>
                <li>Address: Gelvonų g. 36-99, Vilnius 01121</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default TermsOfUse;
