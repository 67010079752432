import React from "react";
import { Col, Container, Row } from "reactstrap";
import {
  Service,
  ServiceIcon,
  ServiceText,
} from "../components/service/Service";
import {
  Analytics,
  Paper,
  Responsive,
  Sharing,
  Target,
} from "../components/svg/Icon";
import {
  Section,
  SectionContant,
  SectionHead,
} from "../layout/section/Section";

const AdvantagesSection = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="justify-content-center text-center">
          <Col xs="9" sm="7" md="6">
            <SectionHead>
              <h2 className="title">Why Choose incwt.com?</h2>
            </SectionHead>
          </Col>
        </Row>
        <SectionContant>
          <Row className="gy-gs justify-content-center text-center">
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4 after-bg-info">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-info">
                    <Paper />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">Regulated and Compliant</h5>
                    <p>
                      We operate under the supervision of the Central Bank of
                      Lithuania and fully adhere to European Union regulations,
                      including stringent KYC (Know Your Customer) and AML
                      (Anti-Money Laundering) requirements. Our compliance
                      ensures your peace of mind and a secure trading
                      experience.
                    </p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-primary">
                    <Responsive />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      Flexible Exchange Options
                    </h5>
                    <div>
                      <ul>
                        <li>
                          <strong>Online Registration & Exchange:</strong> Sign
                          up, verify your identity through our trusted
                          EU-licensed KYC provider, and trade securely using
                          your bank account.
                        </li>
                        <li>
                          <strong>In-Person Services:</strong> If online
                          registration isn’t successful, you can visit our
                          office to complete the registration and exchange
                          process in person.
                        </li>
                      </ul>
                    </div>
                  </ServiceText>
                </div>
              </Service>
            </Col>
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4 after-bg-danger">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-danger">
                    <Analytics />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      Cash Exchange Services (In-Person)
                    </h5>
                    <p>
                      Subject to all legal restrictions under Lithuanian law,
                      you can exchange cryptocurrencies for cash at our office.
                      Our in-person services comply with all AML, KYC, and cash
                      operation regulations, making your transactions safe and
                      lawful.
                    </p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
          </Row>
          <Row className="gy-gs justify-content-center text-center">
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4 after-bg-info">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-info">
                    <Sharing />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      Secure and Private Transactions
                    </h5>
                    <p>
                      Your data and funds are protected with industry-leading
                      security measures and privacy protocols. We do not
                      compromise on safeguarding your information.
                    </p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-primary">
                    <Target />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">Live Exchange Rates</h5>
                    <p>
                      Access real-time cryptocurrency exchange rates on our
                      platform, ensuring transparency and competitive pricing.
                    </p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
          </Row>
        </SectionContant>
      </Container>
    </Section>
  );
};

export default AdvantagesSection;
