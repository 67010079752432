import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Carousel,
  CarouselControl,
  CarouselItem,
  Col,
  Container,
  Row,
} from "reactstrap";
import { PairItem } from "../components/pairs/pair";
import { useActivePairs } from "../hooks/useActivePairs";
import { useHistoricalTrendData } from "../hooks/useQuoteData";
import { Section } from "../layout/section/Section";

const QuotesSection = (props) => {
  const { data: activePairs, loading, error } = useActivePairs();
  const [numCards, setNumCards] = useState(4); // Default to 4 cards

  const pairIds = useMemo(
    () =>
      activePairs?.map((pair) => `${pair.base.code}/${pair.quote.code}`) || [],
    [activePairs]
  );
  const groupedActivePairs = useMemo(() => {
    const grouped = [];
    for (let i = 0; i < activePairs?.length; i += numCards) {
      grouped.push(activePairs.slice(i, i + numCards));
    }
    return grouped;
  }, [activePairs, numCards]);
  const { data: trend } = useHistoricalTrendData(pairIds);
  const [activeIndex, setActiveIndex] = useState(0);

  // Function to handle window resizing and update the number of visible cards
  const updateCardsBasedOnScreenWidth = () => {
    const width = window.innerWidth;
    console.log("Width: ", width);
    if (width >= 1200) {
      setNumCards(4); // Large screens, show 4 cards
    } else if (width >= 768) {
      setNumCards(3); // show 3 cards
    } else {
      setNumCards(2); // Small screens, show 1 card
    }
  };

  useEffect(() => {
    // Initialize number of cards based on the screen width
    updateCardsBasedOnScreenWidth();

    // Set up resize listener
    window.addEventListener("resize", updateCardsBasedOnScreenWidth);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", updateCardsBasedOnScreenWidth);
    };
  }, [numCards]);

  const goToIndex = (newIndex) => {
    if (newIndex >= groupedActivePairs.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = groupedActivePairs.length - 1;
    }
    setActiveIndex(newIndex);
  };

  const next = () => {
    goToIndex(activeIndex + 1);
  };

  const previous = () => {
    goToIndex(activeIndex - 1);
  };

  const slides = useMemo(
    () =>
      activePairs?.length > 0 &&
      groupedActivePairs.map((group, index) => (
        <CarouselItem key={index} className="carousel-item">
          <Row>
            {group.map((pair, subIndex) => (
              <Col key={subIndex} className="carousel-column">
                <Link to={`/exchange/${pair.quote.code}/${pair.base.code}`}>
                  <div className="team team-s1">
                    <div className="team-info">
                      <PairItem
                        pair={pair}
                        trend={trend[`${pair.base.code}/${pair.quote.code}`]}
                      />
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </CarouselItem>
      )),
    [activePairs, groupedActivePairs, trend]
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading active pairs</div>;

  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container className="quotes-container">
        <Row className="align-items-center gx-5 gy-4 text-center">
          <Col className="mt-0">
            {activePairs && (
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={false}
              >
                {slides}
                <CarouselControl
                  directionText="Prev"
                  direction="prev"
                  onClickHandler={previous}
                />
                <CarouselControl
                  directionText="Next"
                  direction="next"
                  onClickHandler={next}
                />
              </Carousel>
            )}
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default QuotesSection;
