import React, { useContext } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import ActivePairs from "../../components/exchange/ActivePairs";
import ExchangeForm from "../../components/exchange/form";
import { AppContext } from "../../context/AppContext";
import PageLayout from "../../layout/page-layout/PageLayout";
import { Section } from "../../layout/section/Section";

const ExchangeFormPage = () => {
  const { isReady } = useContext(AppContext);
  const { from, to } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <PageLayout>
      <Section className="bg-lighter exchange-form-section">
        <Container className="ecxhange-form">
          <ActivePairs showBoth={false} />
          <div className="buysell-container">
            {isReady && (
              <ExchangeForm
                from={from}
                to={to}
                initalAmount={searchParams.get("amount")}
              />
            )}
          </div>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default ExchangeFormPage;
