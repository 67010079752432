import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { Navigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import { Button, Container } from "reactstrap";
import * as Yup from "yup";
import { DoneModal } from "../../components/exchange/doneModal";
import { NumberField } from "../../components/fields/NumberField";
import { AssetLogo } from "../../components/pairs/pair";
import { API_SERVER } from "../../consts";
import { AppContext } from "../../context/AppContext";
import { Section } from "../../layout/section/Section";
import FooterSection from "../../section/FooterSection";
import HeaderSection from "../../section/HeaderSection";

const RequestInfo = ({ quote, fromCurrency, toCurrency, amount }) => {
  return (
    <div className="request-info-wrapper">
      <h5>Request Info</h5>

      <div className="request-steps-desc">
        <div>
          <strong className="form-label">From:</strong>{" "}
          <AssetLogo asset={fromCurrency} /> {fromCurrency.name}
        </div>
        <div>
          <strong className="form-label">To:</strong>{" "}
          <AssetLogo asset={toCurrency} /> {toCurrency.name}
        </div>
        <div>
          <strong className="form-label">Rate:</strong>{" "}
          <NumberField value={quote?.rate} digits={fromCurrency.decimals} />{" "}
          {fromCurrency.code}/{toCurrency.code}
        </div>
        <div>
          <strong className="form-label">Amount:</strong>{" "}
          <NumberField value={amount} digits={fromCurrency.decimals} />{" "}
          {toCurrency.code}
        </div>
        <div>
          <strong className="form-label">Price:</strong>{" "}
          <NumberField
            value={amount * quote?.rate}
            digits={fromCurrency.decimals}
          />{" "}
          {fromCurrency.code}
        </div>
      </div>
    </div>
  );
};

const StepSidebar = () => {
  const { current, total } = useSteps();
  const stepTitles = [
    "Contact Information",
    "Additional Information",
    "Review & Submit",
  ];

  return (
    <div className="request-steps">
      <div className="nav nav-pills">
        {stepTitles.map((title, index) => (
          <div className="nav-item" key={index}>
            <div
              className={`nav-link ${index + 1 === current ? "active" : ""}`}
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              {title}
            </div>
            {index !== total - 1 && (
              <svg width="50" height="20">
                <line
                  x1="0"
                  y1="10"
                  x2="50"
                  y2="10"
                  stroke="#ccc"
                  strokeWidth="2"
                />
              </svg>
            )}
          </div>
        ))}
      </div>
      <br />
    </div>
  );
};

// Схема валидации с использованием Yup
const validationSchemaContact = Yup.object({
  name: Yup.string().required("Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  phone: Yup.string()
    .matches(/^[0-9+\-() ]*$/, "Phone number can only contain numbers, symbols and spaces.")
    .required("Phone number is required.")
    .min(7, "Phone number must be at least 7 digits.")
    .max(20, "Phone number must be at most 20 digits."),
});

const ContactForm = ({ formState, setFormState }) => {
  const { next } = useSteps();

  const handleSubmit = (values) => {
    setFormState(values);
    next();
  };

  return (
    <div>
      <h3>Contact Information</h3>
      <Formik
        initialValues={formState}
        validationSchema={validationSchemaContact}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            {/* Поле "Name" */}
            <div className="form-group">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <Field
                type="text"
                name="name"
                id="name"
                className="form-control"
              />
              {/* Ошибка для "Name" */}
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>

            {/* Поле "Email" */}
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <Field
                type="email"
                name="email"
                id="email"
                className="form-control"
              />
              {/* Ошибка для "Email" */}
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </div>

            {/* Поле "Phone" */}
            <div className="form-group">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <Field
                type="tel"
                name="phone"
                id="phone"
                className="form-control"
              />
              {/* Ошибка для "Phone" */}
              <ErrorMessage
                name="phone"
                component="div"
                className="text-danger"
              />
            </div>

            {/* Кнопка перехода */}
            <Button
              type="submit"
              className="btn-lg"
              color="primary"
              disabled={!isValid}
            >
              Next
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const validationSchemaInfo = Yup.object({
  additional_info: Yup.string(),
});

const AdditionalInfoForm = ({ formState, setFormState }) => {
  const { next, prev } = useSteps();

  const handleSubmit = (values) => {
    setFormState(values);
    next();
  };

  return (
    <div>
      <h3>Additional Information</h3>
      <Formik
        initialValues={formState}
        validationSchema={validationSchemaInfo}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <div className="form-group additional-info-form">
              <label htmlFor="name" className="form-label">
                Additional Information
              </label>
              <Field
                as="textarea"
                name="additional_info"
                id="additional_info"
                className="form-control"
              />
              <ErrorMessage
                name="additional_info"
                component="div"
                className="text-danger"
              />
            </div>

            <Button color="secondary" onClick={prev} className="me-2 btn-lg">
              Back
            </Button>

            <Button
              type="submit"
              color="primary"
              disabled={!isValid}
              className="btn-lg"
            >
              Next
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

// Компонент ReviewForm
const ReviewForm = ({ formState, quote }) => {
  const { prev } = useSteps();
  const onFormCancel = () => {
    setModal({ confirm: false, buyCoin: false });
  };
  const [modal, setModal] = useState({
    confirm: false,
    buyCoin: false,
  });

  const [data, setData] = useState(null);

  const handleSubmit = () => {
    const request = new FormData();
    request.append("amount", formState.amount);
    request.append("from_currency", formState.fromCurrency.id);
    request.append("to_currency", formState.toCurrency.id);
    request.append("exchange_rate", quote?.rate || "");
    request.append("contact_email", formState.email);
    request.append("contact_name", formState.name);
    request.append("contact_phone", formState.phone);
    request.append("additional_info", formState.additional_info);
    setData({ ...formState, rate: quote?.rate });

    fetch(`${API_SERVER}/api/submit-exchange-request`, {
      method: "POST",
      body: request, // Передаем FormData как тело запроса
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setModal({ confirm: true, buyCoin: false });
        } else {
          alert("Error occurred while placing request.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error occurred while placing request.");
      });
  };

  return (
    <div>
      <h3>Review Your Information</h3>
      <div className="review-info-wrapper">
        <div>
          <h5>Contact Information</h5>
          <ul className="contact-info-block">
            <li>
              <strong className="form-label">Name:</strong> {formState.name}
            </li>
            <li>
              <strong className="form-label">Email:</strong> {formState.email}
            </li>
            <li>
              <strong className="form-label">Phone:</strong> {formState.phone}
            </li>
            <li>
              <strong className="form-label">Additional info:</strong>
              <br /> {formState.additional_info}
            </li>
          </ul>
        </div>

        <RequestInfo
          quote={quote}
          fromCurrency={formState.fromCurrency}
          toCurrency={formState.toCurrency}
          amount={formState.amount}
        />
      </div>

      <Button color="secondary" onClick={prev} className="me-2 btn-lg">
        Back
      </Button>
      <Button color="primary" onClick={handleSubmit} className="btn-lg">
        Submit Request
      </Button>
      <DoneModal
        onFormCancel={onFormCancel}
        modal={modal}
        setModal={setModal}
        data={data}
      />
    </div>
  );
};

const ExchangeRequestFormWrapper = () => {
  const { current, total } = useSteps();
  const { currencies, quotes } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const { from, to } = useParams();

  const fromCurrency = currencies.find((currency) => currency.code === from);
  const toCurrency = currencies.find((currency) => currency.code === to);
  const quote = quotes.get(`${fromCurrency.code}/${toCurrency.code}`);

  const [formState, setFormState] = useState({
    fromCurrency,
    toCurrency,
    amount: searchParams.get("amount"),
    additional_info: "",
    name: "",
    email: "",
    phone: "",
  });
  return (
    <Container className="exchange-request-form-wrapper">
      {current !== total && (
        <div className="request-info">
          <RequestInfo
            quote={quote}
            fromCurrency={fromCurrency}
            toCurrency={toCurrency}
            amount={formState.amount}
          />
        </div>
      )}
      <div className="request-form">
        <div>
          <StepSidebar />
          <Steps>
            <ContactForm formState={formState} setFormState={setFormState} />
            <AdditionalInfoForm
              formState={formState}
              setFormState={setFormState}
            />
            <ReviewForm formState={formState} quote={quote} />
          </Steps>
        </div>
      </div>
    </Container>
  );
};

// Главный компонент
const ExchangeRequestFormPage = () => {
  const { currencies } = useContext(AppContext);
  const { from, to } = useParams();

  const fromCurrency = currencies.find((currency) => currency.code === from);
  const toCurrency = currencies.find((currency) => currency.code === to);

  if (!fromCurrency || !toCurrency) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="nk-main">
      <HeaderSection
        className="header has-header-main-s1 bg-lighter"
        id="#home"
      />
      <Section className="request bg-lighter">
        <StepsProvider>
          <ExchangeRequestFormWrapper />
        </StepsProvider>
      </Section>
      <FooterSection className="bg-lighter" id="#footer" />
    </div>
  );
};

export default ExchangeRequestFormPage;
