import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "../components/button/Button";
import { ImageBlock } from "../components/images/Images";
import {
  Paragraph,
  TextBlock,
  TitleH2,
} from "../components/textBlock/TextBlock";
import ThumbImg1 from "../images/gfx/d.png";
import { Section } from "../layout/section/Section";

const ContactSection = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="g-gs justify-content-between align-items-center">
          <Col lg="6">
            <ImageBlock>
              <img src={ThumbImg1} alt="thumb-img" />
            </ImageBlock>
          </Col>
          <Col lg="5">
            <TextBlock>
              <TitleH2 className="mb-3">
                Have questions or need assistance?
              </TitleH2>

              <Paragraph>Get in touch with us:</Paragraph>
              <ul className="btns-inline">
                <li>
                  <Link
                    to="https://1.envato.market/reactdashlite"
                    target="_blank"
                    rel="noreferrer"
                    className="btn-lg btn-primary"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default ContactSection;
