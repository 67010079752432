import React from "react";
import { Col, Container, Row } from "reactstrap";
import PageLayout from "../../layout/page-layout/PageLayout";
import { Section, SectionHead } from "../../layout/section/Section";

const PrivacyPolicy = () => {
  return (
    <PageLayout>
      <Section className="rules-section">
        <Container>
          <Row>
            <Col>
              <SectionHead>
                <h2 className="title">Privacy Policy</h2>
              </SectionHead>
              <p>
                <strong>Last Updated:</strong> November 26, 2024
              </p>

              <p>
                Labasnamas, UAB (“we,” “our,” or “us”) values your privacy and
                is committed to protecting your personal data. This Privacy
                Policy outlines how we collect, use, process, and protect your
                information when you use our cryptocurrency exchange platform
                and services.
              </p>

              <h5 className="mt-4">1. Who We Are</h5>
              <p>
                Labasnamas, UAB is a cryptocurrency exchange licensed by the
                Central Bank of Lithuania. We are fully compliant with European
                Union regulations, including GDPR, and other applicable legal
                requirements.
              </p>

              <h5 className="mt-4">2. What Data We Collect</h5>
              <p>We may collect the following types of personal data:</p>

              <h6 className="mt-3">a. Data You Provide Directly:</h6>
              <ul>
                <li>
                  Full name, date of birth, and contact information (email,
                  phone number, address).
                </li>
                <li>
                  Government-issued identification documents (e.g., passport,
                  national ID, residency permit).
                </li>
                <li>
                  Selfie or live verification data (for identity verification).
                </li>
                <li>
                  Financial account details for transactions (e.g., bank account
                  details).
                </li>
                <li>Proof of address (utility bills, bank statements).</li>
              </ul>

              <h6 className="mt-3">b. Data Collected Automatically:</h6>
              <ul>
                <li>
                  IP address, browser type, operating system, and device
                  details.
                </li>
                <li>
                  Log-in and activity data (e.g., access times, pages viewed).
                </li>
                <li>Cookies or similar technologies for session tracking.</li>
              </ul>

              <h6 className="mt-3">c. Third-Party Data:</h6>
              <ul>
                <li>
                  Identity verification results from our licensed KYC provider.
                </li>
                <li>Sanctions screening results and risk assessments.</li>
              </ul>

              <h5 className="mt-4">3. How We Use Your Data</h5>
              <p>We process your data for the following purposes:</p>
              <ul>
                <li>
                  Account Registration and Verification: To verify your identity
                  and comply with legal KYC/AML requirements.
                </li>
                <li>
                  Transaction Processing: To enable cryptocurrency exchanges and
                  related services.
                </li>
                <li>
                  Compliance with Legal Obligations: To adhere to laws regarding
                  anti-money laundering (AML), counter-terrorism financing
                  (CTF), and sanctions screening.
                </li>
                <li>
                  Communication: To send service updates, confirmations, and
                  customer support responses.
                </li>
                <li>
                  Improvement of Services: To analyze platform usage and improve
                  user experience.
                </li>
              </ul>

              <h5 className="mt-4">4. Legal Basis for Processing</h5>
              <p>We process your data under the following legal bases:</p>
              <ul>
                <li>
                  Consent: When you voluntarily provide data during
                  registration.
                </li>
                <li>
                  Contractual Necessity: To perform the exchange services as per
                  our terms and conditions.
                </li>
                <li>
                  Legal Obligation: To comply with KYC/AML laws,
                  counter-terrorism financing requirements, and other regulatory
                  mandates.
                </li>
                <li>
                  Legitimate Interest: To improve our services and prevent
                  fraudulent activities.
                </li>
              </ul>

              <h5 className="mt-4">5. Who We Share Your Data With</h5>
              <p>
                We only share your data with trusted third parties when
                necessary:
              </p>
              <ul>
                <li>
                  KYC Providers: For identity verification and compliance.
                </li>
                <li>
                  Payment Processors and Banks: To facilitate bank account
                  transactions.
                </li>
                <li>
                  Regulatory Authorities: When required by Lithuanian law or
                  international agreements.
                </li>
                <li>
                  Sanctions Screening Services: To ensure compliance with
                  sanctions and high-risk country restrictions.
                </li>
              </ul>
              <p>
                We do not sell or share your personal data with unauthorized
                third parties.
              </p>

              <h5 className="mt-4">6. Data Retention</h5>
              <p>We retain your personal data for as long as necessary to:</p>
              <ul>
                <li>
                  Comply with our legal obligations (e.g., AML record-keeping
                  requirements).
                </li>
                <li>Resolve disputes and enforce our agreements.</li>
              </ul>
              <p>
                Typically, data is retained for a minimum of 3 years after
                account closure or as required by law.
              </p>

              <h5 className="mt-4">7. Data Security</h5>
              <p>
                We implement robust security measures to protect your personal
                data:
              </p>
              <ul>
                <li>Encrypted data storage.</li>
                <li>Secure communication protocols (e.g., HTTPS).</li>
                <li>Regular security audits and updates.</li>
                <li>
                  Limited access to sensitive data for authorized personnel
                  only.
                </li>
              </ul>
              <p>
                However, no system is completely secure, and we encourage you to
                take steps to protect your account credentials.
              </p>

              <h5 className="mt-4">8. Your Rights</h5>
              <p>Under GDPR, you have the following rights:</p>
              <ul>
                <li>Access: Request a copy of the data we hold about you.</li>
                <li>
                  Rectification: Request corrections to incomplete or inaccurate
                  data.
                </li>
                <li>
                  Erasure: Request deletion of your data (subject to legal
                  limitations).
                </li>
                <li>Restriction: Request limitation of data processing.</li>
                <li>
                  Data Portability: Receive your data in a structured,
                  machine-readable format.
                </li>
                <li>
                  Objection: Object to processing based on legitimate interests
                  or direct marketing.
                </li>
                <li>
                  Withdrawal of Consent: Revoke consent where processing is
                  based on consent.
                </li>
              </ul>
              <p>
                To exercise your rights, please contact us at{" "}
                <a href="mailto:privacy@incwt.com">privacy@incwt.com</a>.
              </p>

              <h5 className="mt-4">9. Cookies and Tracking</h5>
              <p>
                We use cookies to enhance your browsing experience. You can
                manage cookie preferences through your browser settings. For
                more details, refer to our Cookie Policy.
              </p>

              <h5 className="mt-4">10. International Data Transfers</h5>
              <p>
                Your data may be transferred outside the EU to trusted partners
                or service providers. We ensure such transfers comply with GDPR
                through:
              </p>
              <ul>
                <li>Standard Contractual Clauses (SCCs).</li>
                <li>Adequate safeguard measures.</li>
              </ul>

              <h5 className="mt-4">
                11. High-Risk Countries and U.S. Restrictions
              </h5>
              <p>
                We do not provide services to residents of high-risk countries
                as defined by Lithuanian law and international sanctions or to
                residents of the United States.
              </p>

              <h5 className="mt-4">12. Changes to This Policy</h5>
              <p>
                We may update this Privacy Policy to reflect changes in
                regulations or services. Any updates will be posted on this page
                with a revised “Last Updated” date.
              </p>

              <h5 className="mt-4">13. Contact Us</h5>
              <p>
                If you have questions or concerns about this Privacy Policy,
                please contact us:
              </p>
              <ul>
                <li>
                  Email:{" "}
                  <a href="mailto:privacy@incwt.com">privacy@incwt.com</a>
                </li>
                <li>Address: Gelvonų g. 36-99, Vilnius 01121</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default PrivacyPolicy;
