import React, { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import PageLayout from "../layout/page-layout/PageLayout";
import AdvantagesSection from "../section/AdvantagesSection";
import CommitmentSection from "../section/CommitmentSection";
import ContactSection from "../section/ContactSection";
import GetStartedSection from "../section/GetStartedSection";
import SecuritySection from "../section/SecuritySection";
import WelcomeSection from "../section/WelcomeSection";
import QuotesSection from "../section/QuotesSection";

const HomePage = (props) => {
  useEffect(() => {
    // Если на странице есть якорь, прокручиваем к нему после рендеринга
    const anchor = window.location.hash;
    if (anchor) {
      const element = document.getElementById(anchor.substring(1));
      if (element) {
        scroll.scrollTo(element.offsetTop, {
          duration: 500,
          smooth: "easeInOutQuad",
        });
      }
    }
  }, []);

  return (
    <PageLayout>
      <WelcomeSection className="section-welcome bg-lighter" id="home" />
      <AdvantagesSection className="section section-advantages" id="advantages" />
      <CommitmentSection
        className="section-commitment bg-lighter"
        id="commitment"
      />
      <GetStartedSection className="section-get-started" id="getstarted"/>
      <SecuritySection className="section-security bg-lighter" />
      <ContactSection className="section-contact" />
      <QuotesSection className="section-quotes section-sm bg-lighter" />
    </PageLayout>
  );
};

export default HomePage;
