import React, { Suspense, useContext, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import Loader from "./components/loader/loader";
import { AppContext, AppProvider } from "./context/AppContext";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Error404Classic from "./pages/error/404-classic";
import ExchangeFormPage from "./pages/exchange/ExchangeFormPage";
import ExchangeReqestFormPage from "./pages/exchange/ExchangeRequestPage";
import HomePage from "./pages/HomePage";
import CookiePolicy from "./pages/rules/CookiePolicy";
import PrivacyPolicy from "./pages/rules/PrivacyPolicy";
import TermsOfUse from "./pages/rules/TermsOfUse";

const Guard = () => {
  const { isAppReady, isReady } = useContext(AppContext);

  if (!isAppReady || !isReady) {
    return <Loader />;
  }

  return <Outlet />;
};

function App() {
  useEffect(() => {
    document.body.className =
      "nk-body bg-white npc-landing no-touch nk-nio-theme";
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="nk-app-root">
        <AppProvider>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}/rules`}>
              <Route path="privacy" element={<PrivacyPolicy />}></Route>
              <Route path="cookie" element={<CookiePolicy />}></Route>
              <Route path="terms" element={<TermsOfUse />}></Route>
              {/* <Route path="auth-success" element={<Success />}></Route> */}
            </Route>
            <Route
              path={`${process.env.PUBLIC_URL}/register`}
              element={<Register />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              element={<Login />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/reset`}
              element={<ForgotPassword />}
            ></Route>
            <Route element={<Guard />}>
              <Route
                index
                path={`${process.env.PUBLIC_URL}`}
                element={<HomePage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/exchange/:from/:to`}
                element={<ExchangeFormPage />}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/request/:from/:to`}
                element={<ExchangeReqestFormPage />}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/exchange`}
                element={<ExchangeFormPage />}
              ></Route>
            </Route>
            <Route path="*" element={<Error404Classic />}></Route>
          </Routes>
        </AppProvider>
        <CookieConsent
          disableStyles
          buttonClasses="btn btn-primary"
          containerClasses="alert-fill alert alert-light fade show сookie-bar"
          contentClasses="text-capitalize text"
          buttonText="Accept All"
        >
          We use cookies to enhance your experience, analyze traffic, and personalize content. By clicking ‘Accept All’, you consent to our use of cookies. For details, visit our <Link to="/rules/cookie">Cookie Policy</Link>.
        </CookieConsent>
      </div>
    </Suspense>
  );
}

export default App;
