import { useContext } from "react";
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledDropdown } from "reactstrap";
import { AppContext } from "../../context/AppContext";
import { NumberField } from "../fields/NumberField";
import { AssetLogo } from "../pairs/pair";


const CurrencyOption = ({ currency, otherCurrency }) => {
    const code = currency.code.toLowerCase();
    const { quotes } = useContext(AppContext);
    const quote = quotes.get(`${otherCurrency?.code}/${currency.code}`);

    return (
        <span className={`coin-item ${code}`}>
            <AssetLogo asset={currency} />
            <span className="coin-info">
                <span className="coin-name">{currency.name} ({currency.code})</span>
                {quote && <span className="coin-text">{otherCurrency.code}/{currency.code}: <NumberField value={quote ? quote.rate : 0} digits={currency.decimals} /> </span>}
            </span>
        </span>
    );
}

export const CurrencyDropdown = ({ label, name, options, currency, setCurrency, otherCurrency }) => {

    return (
        <div className="form-group buysell-field">
            <div className="form-label-group">
                <Label for="buysell-choose-currency" className="form-label">
                    {label}
                </Label>
            </div>
            <Input type="hidden" value={currency?.code || ""} name={name} id="buysell-choose-currency" />
            <UncontrolledDropdown className="buysell-cc-dropdown">
                <DropdownToggle
                    className="buysell-cc-choosen dropdown-indicator"
                    tag="a"
                    href="toggle"
                    onClick={(ev) => ev.preventDefault()}
                >
                    {currency && <CurrencyOption currency={currency} />}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                    <div className="buysell-cc-list">
                        {options.map((option) => (
                            <DropdownItem key={option.code}
                                onClick={() => [setCurrency(option)]}
                                className="buysell-cc-item selected"
                            >
                                <Link
                                    href="#"
                                    to="#"
                                    className="buysell-cc-opt"
                                    data-currency={option.code}
                                >
                                    <CurrencyOption currency={option} otherCurrency={otherCurrency} />
                                </Link>
                            </DropdownItem>
                        ))}
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};
