import classNames from "classnames";
import React from "react";

const NioIcon = (props) => {
    let icon = 'ni-' + props.icon;
    return (
        <em className={['ni', 'icon', icon, props.className ? props.className : null].join(' ')}></em>
    )
}


const SocialIcon = (props) => {
    return (
        <ul className={['social', props.className ? props.className : null].join(' ')}>
            {props.data.map((data, index) =>
                <li key={index}><a href={data.to} onClick={(ev) => ev.preventDefault()}><NioIcon icon={data.icon} /></a></li>
            )}
        </ul>
    )
}

const StyledIcon = (props) => {
    return (
        <div className={['styled-icon', props.className ? props.className : null].join(' ')}>
            {props.children}
        </div>

    )
}

const Icon = ({ name, id, className, style, ...props }) => {
    const iconClass = classNames({
      [`${className}`]: className,
      icon: true,
      ni: true,
      [`ni-${name}`]: true,
    });
    return <em className={iconClass} id={id} style={style} {...props}></em>;
  };
  

const LogoIcon = <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="100.000000pt"
    height="50.000000pt"
    viewBox="0 0 397.000000 230.000000"
    preserveAspectRatio="xMidYMid meet"
>
    <g
        transform="translate(0.000000,230.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
    >
        <path
            d="M810 1898 c-151 -90 -337 -199 -413 -243 l-137 -80 6 -475 c3 -261 7
      -480 9 -486 2 -6 68 -47 147 -91 78 -45 213 -120 298 -169 85 -48 209 -118
      274 -154 l119 -67 196 115 c430 253 616 364 625 372 4 5 -5 15 -21 24 l-30 15
      -384 -225 c-348 -205 -386 -224 -408 -214 -33 15 -733 410 -748 423 -10 7 -13
      110 -13 456 l1 446 375 220 c207 121 380 222 385 223 8 3 235 -123 673 -373
      107 -62 109 -62 137 -46 16 9 29 18 29 21 -1 7 -806 461 -827 466 -11 3 -132
      -63 -293 -158z"
        />
        <path
            d="M954 1876 c-122 -73 -386 -229 -541 -320 l-53 -31 0 -430 0 -430 158
      -89 c86 -49 255 -145 375 -213 l218 -123 370 217 c203 120 363 219 355 221 -8
      2 -173 -90 -366 -203 -193 -113 -357 -205 -365 -203 -8 2 -174 93 -369 203
      l-355 200 0 420 -1 420 163 95 c89 52 227 133 307 180 80 48 168 99 196 115
      l52 29 148 -84 c82 -46 201 -113 264 -149 246 -139 306 -171 320 -171 8 0 10
      3 5 9 -10 8 -646 370 -711 404 -30 15 -30 15 -170 -67z"
        />
        <path d="M1105 1518 c-9 -24 -2 -93 10 -93 14 0 19 85 6 98 -7 7 -12 5 -16 -5z" />
        <path
            d="M900 1465 c0 -11 11 -15 40 -15 38 0 40 -2 40 -30 0 -16 5 -30 10
      -30 6 0 10 20 10 45 l0 45 -50 0 c-38 0 -50 -4 -50 -15z"
        />
        <path
            d="M1230 1435 c0 -51 22 -60 28 -12 3 22 9 26 40 29 55 5 45 23 -15 26
      l-53 3 0 -46z"
        />
        <path
            d="M1002 1354 c-153 -77 -184 -284 -60 -400 122 -116 313 -88 393 57 26
      46 30 64 29 124 0 78 -22 132 -71 176 -56 50 -85 62 -163 67 -66 3 -81 0 -128
      -24z m223 -26 c37 -20 67 -52 91 -98 24 -45 25 -153 2 -195 -78 -145 -266
      -168 -371 -45 -135 158 0 392 208 360 22 -4 54 -14 70 -22z"
        />
        <path
            d="M1070 1255 c0 -8 -9 -15 -19 -15 -26 0 -37 -18 -17 -26 23 -9 23
      -159 0 -168 -18 -7 -9 -26 13 -26 9 0 22 -8 29 -17 12 -17 13 -17 19 0 9 21
      35 23 35 2 0 -20 12 -19 32 3 9 9 23 23 32 29 12 10 15 32 13 100 -2 81 -4 89
      -24 98 -13 5 -23 15 -23 22 0 7 -7 13 -15 13 -8 0 -15 -7 -15 -15 0 -8 -7 -15
      -15 -15 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z m110 -75 c0
      -29 -1 -30 -50 -30 -49 0 -50 1 -50 30 0 29 1 30 50 30 49 0 50 -1 50 -30z
      m-2 -97 l3 -33 -50 0 -51 0 0 36 0 35 48 -3 c45 -3 47 -4 50 -35z"
        />
        <path d="M956 1143 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
        <path d="M1256 1142 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z" />
        <path
            d="M2267 1362 c-21 -23 -25 -190 -5 -210 16 -16 180 -16 196 0 7 7 12
      22 12 35 0 18 -6 23 -25 23 -14 0 -25 -4 -25 -10 0 -6 -27 -10 -60 -10 l-60 0
      0 70 0 70 56 0 c41 0 60 -5 69 -17 15 -20 45 -11 45 15 0 37 -32 52 -112 52
      -60 0 -78 -4 -91 -18z"
        />
        <path
            d="M2507 1373 c-4 -3 -7 -57 -7 -120 l0 -113 25 0 c22 0 25 4 25 35 0
          33 2 35 33 35 31 0 87 -38 87 -60 0 -6 9 -10 20 -10 23 0 28 38 8 58 -9 9 -9
          15 0 24 7 7 12 39 12 73 0 34 -5 66 -12 73 -13 13 -179 17 -191 5z m163 -78
          l0 -35 -60 0 -60 0 0 35 0 35 60 0 60 0 0 -35z"
        />
        <path
            d="M2917 1374 c-4 -4 -7 -21 -7 -39 0 -24 -7 -35 -30 -48 -29 -17 -31
          -17 -60 0 -24 14 -30 24 -30 50 0 29 -3 33 -25 33 -22 0 -25 -4 -25 -39 0 -36
          5 -43 45 -72 41 -30 45 -36 45 -76 0 -36 3 -43 20 -43 17 0 20 7 20 43 0 40 4
          46 46 76 42 31 45 35 42 74 -3 40 -22 59 -41 41z"
        />
        <path
            d="M3008 1373 l-28 -4 0 -115 0 -114 25 0 c22 0 25 4 25 35 l0 35 73 0
      c43 0 78 5 85 12 18 18 15 123 -4 142 -15 16 -103 20 -176 9z m142 -78 l0 -35
      -60 0 -60 0 0 35 0 35 60 0 60 0 0 -35z"
        />
        <path
            d="M3227 1373 c-4 -3 -7 -15 -7 -25 0 -14 8 -18 40 -18 l40 0 0 -95 c0
      -95 0 -95 25 -95 25 0 25 0 25 95 l0 95 40 0 c36 0 40 3 40 25 0 25 0 25 -98
      25 -54 0 -102 -3 -105 -7z"
        />
        <path
            d="M3478 1369 c-15 -8 -18 -25 -18 -108 0 -72 4 -102 14 -110 9 -8 46
      -11 103 -9 l88 3 3 105 c2 90 0 107 -15 117 -20 16 -150 17 -175 2z m150 -111
      l-3 -73 -54 0 c-52 0 -55 1 -63 30 -5 17 -6 49 -3 73 l6 42 60 0 60 0 -3 -72z"
        />
        <path d="M1780 1255 l0 -115 25 0 25 0 0 115 0 115 -25 0 -25 0 0 -115z" />
        <path
            d="M1870 1255 c0 -108 1 -115 20 -115 18 0 20 7 20 75 0 41 3 75 8 75 4
0 32 -34 61 -75 41 -56 61 -75 78 -75 23 0 23 2 23 115 0 108 -1 115 -20 115
-18 0 -20 -7 -20 -70 0 -38 -3 -70 -8 -70 -4 0 -31 32 -61 70 -40 52 -59 70
-77 70 -24 0 -24 0 -24 -115z"
        />
        <path
            d="M770 1300 c0 -27 3 -50 8 -51 4 0 18 -2 32 -3 30 -4 50 3 50 15 0 5
-13 9 -30 9 -28 0 -30 2 -30 40 0 29 -4 40 -15 40 -11 0 -15 -12 -15 -50z"
        />
        <path
            d="M1438 1313 c-3 -33 -7 -38 -30 -41 -43 -5 -33 -22 12 -22 l40 0 0 50
c0 58 -17 68 -22 13z"
        />
        <path d="M727 1144 c-17 -18 -3 -25 43 -22 28 2 51 8 53 13 2 11 -85 20 -96 9z" />
        <path
            d="M1417 1144 c-17 -17 -4 -24 44 -24 36 0 49 4 47 13 -5 13 -79 22 -91
11z"
        />
        <path
            d="M1780 989 c0 -76 2 -84 31 -120 35 -45 69 -51 93 -17 l16 22 23 -22
c34 -32 59 -27 95 17 30 37 32 45 32 120 0 80 0 81 -25 81 -24 0 -25 -2 -25
-74 0 -57 -4 -79 -18 -96 l-19 -23 -16 24 c-12 17 -17 45 -17 96 0 71 -1 73
-25 73 -24 0 -25 -2 -25 -73 0 -51 -5 -79 -17 -96 l-16 -24 -19 23 c-14 17
-18 39 -18 96 0 72 -1 74 -25 74 -25 0 -25 -1 -25 -81z"
        />
        <path
            d="M2100 950 l0 -120 100 0 100 0 0 25 c0 24 -2 25 -75 25 -73 0 -75 1
-75 25 0 24 2 25 71 25 67 0 70 1 67 23 -3 20 -9 22 -70 25 -60 3 -68 5 -68
22 0 18 7 20 75 20 73 0 75 1 75 25 l0 25 -100 0 -100 0 0 -120z"
        />
        <path
            d="M2460 1046 c0 -22 5 -25 43 -28 l42 -3 3 -92 c2 -85 4 -93 22 -93 18
0 20 7 20 94 l0 95 43 3 c34 2 42 7 42 23 0 19 -8 20 -107 23 l-108 3 0 -25z"
        />
        <path
            d="M2700 949 c0 -119 0 -120 23 -117 17 2 23 11 25 36 3 30 6 32 41 32
38 0 81 -31 81 -58 0 -7 9 -12 20 -12 15 0 20 7 20 29 0 17 -5 33 -11 37 -8 4
-8 9 0 17 15 15 14 130 -1 145 -8 8 -46 12 -105 12 l-93 0 0 -121z m170 36 l0
-35 -60 0 -60 0 0 35 0 35 60 0 60 0 0 -35z"
        />
        <path
            d="M2950 963 c0 -72 4 -113 12 -121 7 -7 43 -12 93 -12 50 0 86 5 93 12
8 8 12 49 12 120 0 101 -1 108 -20 108 -19 0 -20 -7 -20 -95 l0 -95 -60 0 -59
0 -3 93 c-3 87 -4 92 -25 95 -23 3 -23 2 -23 -105z"
        />
        <path
            d="M3211 1056 c-7 -8 -11 -37 -9 -68 l3 -53 78 -3 c74 -3 77 -4 77 -27
0 -24 -3 -25 -60 -25 -33 0 -60 4 -60 10 0 6 -9 10 -20 10 -23 0 -28 -38 -8
-58 16 -16 170 -16 186 0 7 7 12 36 12 65 0 64 -12 73 -104 73 -59 0 -66 2
-66 20 0 18 7 20 59 20 33 0 63 -4 66 -10 3 -5 15 -10 26 -10 22 0 27 38 7 58
-18 18 -172 16 -187 -2z"
        />
        <path
            d="M3440 1045 c0 -22 4 -25 40 -25 l40 0 0 -96 c0 -93 1 -95 23 -92 21
3 22 8 25 96 l3 92 39 0 c36 0 40 3 40 25 l0 25 -105 0 -105 0 0 -25z"
        />
        <path
            d="M770 965 c0 -41 3 -56 13 -53 6 3 13 21 15 41 3 31 7 36 30 39 42 5
32 23 -15 26 l-43 3 0 -56z"
        />
        <path
            d="M1380 1005 c0 -10 10 -15 30 -15 28 0 30 -2 30 -40 0 -22 5 -40 10
-40 6 0 10 25 10 55 l0 55 -40 0 c-29 0 -40 -4 -40 -15z"
        />
        <path
            d="M978 843 c-3 -24 -8 -28 -41 -31 -54 -5 -42 -27 14 -27 l44 0 3 43
c3 46 -15 59 -20 15z"
        />
        <path
            d="M1232 828 c3 -39 6 -43 32 -46 36 -4 76 5 76 18 0 6 -18 10 -40 10
-38 0 -40 2 -40 30 0 20 -5 30 -16 30 -12 0 -14 -9 -12 -42z"
        />
        <path
            d="M1107 833 c-4 -3 -7 -26 -7 -50 0 -32 4 -43 15 -43 11 0 15 12 15 50
0 46 -7 60 -23 43z"
        />
    </g>
</svg>

export { NioIcon, SocialIcon, StyledIcon, Icon, LogoIcon };

