import BaseCommService from './BaseCommService';
import { commService } from "./CommunicationService";

export class QuotesService extends BaseCommService {
    timeZoneOffset;
    constructor() {
        super();
        this.timeZoneOffset = new Date().getTimezoneOffset() * 60;
    }

    get hub() {
        return this._connecction.quoteHub;
    }

    static get instance() { return quotesService }

    async UnsubscribeFromFull(pairIds) {
        const subscribeRequest = {
            PairIds: pairIds,
            Type: "Full"
        };
        commService.quoteHub.send("UnsubscribeFromFull", subscribeRequest);
    }

    async SubscribeTo(pairIds) {
        const subscribeRequest = {
            PairIds: pairIds,
            Type: "Full"
        };
        commService.quoteHub.send("SubscribeTo", subscribeRequest);
    }

    async GetHistoricalData(primeRequest) {
        var respObj = await this._invokeMethod("GetHistoricalData", primeRequest);
        const data = respObj.result.map((item) => ({ ...item, time: item.period - this.timeZoneOffset }));
        return data;
    }

    async GetTechIndicatorData(primeRequest) {
        var respObj = await this._invokeMethod("GetTechIndicatorData", primeRequest);
        const data = respObj.result.map((item) => ({ ...item, time: item.period - this.timeZoneOffset }));
        return data;
    }

    async GetHistoricalTrendBatch(pairIds) {
        if (pairIds.length === 0) {
            return {};
        }
        var respObj = await this._invokeMethod("GetHistoricalTrendBatch", { PairIds: pairIds });
        return respObj.result;
    }
}


export const quotesService = new QuotesService();