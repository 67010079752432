import { useCallback } from 'react';
import { API_SERVER } from '../consts';
import useData from './useData';

export const useActivePairs = () => {
    return useData(useCallback(async () => {
        const response = await fetch(`${API_SERVER}/api/pairs/`);
        return await response.json();
    }, []));
};
