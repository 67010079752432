import * as signalR from '@microsoft/signalr';
import { API_SERVER, QUOTE_SERVER } from '../consts';

export class CommunicationService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _isReady = false;
    _error = null;

    _quoteHubConn;

    updateState(ready, err) {
        this._isReady = ready;
        this._error = err;
        this.notifySubscribers();
    }

    getState() {
        return this._isReady;
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(this._isReady, this._error);
        }
    }

    async ensureInitialized() {
        if (this._quoteHubConn) {
            return { quoteHub: this._quoteHubConn };
        }


        this._quoteHubConn = new signalR.HubConnectionBuilder()
            .withUrl(QUOTE_SERVER + "/quotehub")
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        return { quoteHub: this._quoteHubConn };
    }

    async start() {
        await this.ensureInitialized();
        try {

            if (this._quoteHubConn.state !== signalR.HubConnectionState.Connected) {
                await this._quoteHubConn.start();
            }
            console.log("SignalR Connected.");
            this.updateState(true, null);
        } catch (err) {
            this.updateState(false, err);
            console.error("SignalR error.");
            console.error(err);
            throw err;
        }

        return { quoteHub: this._quoteHubConn };
    };

    get quoteHub() {
        return this._quoteHubConn;
    }
}

export const commService = new CommunicationService();