import React from "react";
import { Col, Container, Row } from "reactstrap";
import PageLayout from "../../layout/page-layout/PageLayout";
import { Section, SectionHead } from "../../layout/section/Section";

const CookiePolicy = () => {
  return (
    <PageLayout>
      <Section className="rules-section">
        <Container>
          <Row>
            <Col>
              <SectionHead>
                <h2 className="title">Cookie Policy</h2>
              </SectionHead>
              <p>
                <strong>Last Updated:</strong> November 26, 2024
              </p>
              <p>
                This Cookie Policy explains how Labasnamas UAB (“we,” “our,” or
                “us”) uses cookies and similar technologies on{" "}
                <a
                  href="https://incwt.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  incwt.com
                </a>{" "}
                (the “Site”). By continuing to use our Site, you consent to the
                use of cookies as described in this policy.
              </p>

              <h5 className="mt-4">1. What Are Cookies?</h5>
              <p>
                Cookies are small text files stored on your device (computer,
                tablet, smartphone) by websites you visit. They help websites
                function properly, enhance user experience, and provide insights
                to improve services.
              </p>

              <h5 className="mt-4">2. Types of Cookies We Use</h5>
              <p>We use the following categories of cookies on incwt.com:</p>

              <h6 className="mt-3">a. Essential Cookies</h6>
              <p>
                These cookies are necessary for the Site’s functionality and
                cannot be turned off in our systems. They include:
              </p>
              <ul>
                <li>Session management cookies.</li>
                <li>Security cookies to protect user accounts.</li>
              </ul>

              <h6 className="mt-3">b. Performance and Analytics Cookies</h6>
              <p>
                These cookies help us understand how visitors interact with our
                Site, including which pages are most visited, so we can improve
                the Site’s performance. We use:
              </p>
              <ul>
                <li>
                  Google Analytics or similar tools (anonymous data collection).
                </li>
                <li>Internal analytics systems.</li>
              </ul>

              <h6 className="mt-3">c. Functionality Cookies</h6>
              <p>
                These cookies allow us to remember your preferences (e.g.,
                language or region) to provide a personalized experience.
              </p>

              <h6 className="mt-3">d. Advertising Cookies</h6>
              <p>
                We may use advertising cookies to deliver targeted
                advertisements based on your browsing habits. These cookies
                ensure ads are relevant and limit the frequency of showing the
                same ad.
              </p>

              <h5 className="mt-4">3. How We Use Cookies</h5>
              <p>We use cookies to:</p>
              <ul>
                <li>Ensure the Site operates efficiently.</li>
                <li>Recognize and remember your preferences.</li>
                <li>Collect anonymized statistics to enhance services.</li>
                <li>Provide tailored content and ads (if applicable).</li>
              </ul>

              <h5 className="mt-4">4. Third-Party Cookies</h5>
              <p>
                Some cookies on our Site are placed by third-party service
                providers, including:
              </p>
              <ul>
                <li>Analytics providers (e.g., Google Analytics).</li>
                <li>Advertising networks.</li>
                <li>Social media platforms.</li>
              </ul>
              <p>
                We do not control these third-party cookies, and you should
                review their privacy policies for more information.
              </p>

              <h5 className="mt-4">5. Your Choices Regarding Cookies</h5>
              <p>
                You can manage or disable cookies through your browser settings.
                Please note:
              </p>
              <ul>
                <li>
                  Blocking essential cookies may impact the functionality of the
                  Site.
                </li>
                <li>
                  Instructions for managing cookies vary by browser (e.g.,
                  Chrome, Firefox, Safari).
                </li>
              </ul>
              <p>
                For more information on managing cookies, visit:{" "}
                <a
                  href="https://www.allaboutcookies.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  All About Cookies
                </a>
                .
              </p>

              <h5 className="mt-4">6. Changes to This Cookie Policy</h5>
              <p>
                We may update this Cookie Policy from time to time. Any changes
                will be posted on this page with a revised “Last Updated” date.
              </p>

              <h5 className="mt-4">7. Contact Us</h5>
              <p>
                If you have questions about this Cookie Policy or how we use
                cookies, please contact us at:
              </p>
              <ul>
                <li>
                  Email:{" "}
                  <a href="mailto:privacy@incwt.com">privacy@incwt.com</a>
                </li>
                <li>Address: Gelvonų g. 36-99, Vilnius 01121</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default CookiePolicy;
