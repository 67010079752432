import { useEffect, useState } from 'react';

export function useData(fetchData) {
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                setLoading(true);
                const data = await fetchData();
                setData(data);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchDataAsync();
    }, [fetchData]);

    return { data, loading, error };
};

export default useData;