import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { NumberField } from '../fields/NumberField';
import { Icon } from '../icon/Icon';

export const DoneModal = ({ onFormCancel, modal, setModal, data }) => {
    
    if (!data) {
        return null;
    }
    
    const { amount, price, fromCurrency, toCurrency } = data;
    return (
        <Modal
            isOpen={modal.confirm}
            toggle={() => setModal({ confirm: false })}
            className="modal-dialog-centered"
            size="md"
        >
            <Link
                href="#close"
                to="/"
                as="a"
                className="close"
            >
                <Icon name="cross-sm" />
            </Link>
            <ModalBody className="modal-body-lg text-center">
                <div className="nk-modal">
                    <Icon name="check" className="nk-modal-icon icon-circle icon-circle-xxl bg-success" />
                    <h4 className="nk-modal-title">Successfully placed order!</h4>
                    <div className="nk-modal-text">
                        <p className="caption-text">
                            Your purchase request for <strong>{amount}</strong> {toCurrency.code} for <strong><NumberField value={price} digits={fromCurrency.decimals} /> </strong> {fromCurrency.code} has been recieved.
                        </p>
                    </div>
                    <div className="nk-modal-action-lg">
                        <ul className="btn-group gx-4">
                            <li>
                                <Link to={`/`} reloadDocument={true} className="btn btn-lg btn-mw btn-primary">
                                    Return
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
