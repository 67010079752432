import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "../components/button/Button";
import ActivePairs from "../components/exchange/ActivePairs";
import {
  HeaderCaption,
  HeaderText,
  HeaderTitle,
} from "../components/headerCaption/HeaderCaption";
import { Paragraph } from "../components/textBlock/TextBlock";
import { Section } from "../layout/section/Section";

const WelcomeSection = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="flex-row-reverse justify-content-between g-gs">
          <Col lg="6" className="mb-3">
            <ActivePairs showBoth={true} />
          </Col>
          <Col lg="6">
            <HeaderCaption>
              {/* <HeaderTitle>Welcome to incwt.com</HeaderTitle> */}
              <HeaderTitle>In Crypto We Trust</HeaderTitle>
              <HeaderText className="pe-5">                
                <p className="welcome-subtitle">
                  Your Trusted Cryptocurrency Exchange Licensed by the Central
                  Bank of Lithuania
                </p>                
                <Paragraph>
                  At incwt.com, operated by Labasnamas UAB, we provide a secure
                  and user-friendly platform for cryptocurrency exchange. As a
                  fully licensed exchange under Lithuanian regulation, we
                  prioritize transparency, compliance, and your convenience.
                  Whether you’re looking to view live rates, register online, or
                  exchange in person, we’ve got you covered.
                </Paragraph>
              </HeaderText>
              <ul className="header-action btns-inline">
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/exchange`}
                    rel="noreferrer"
                    className="btn-primary btn-lg"
                  >
                    <span>Let's Start</span>
                  </Link>
                </li>
              </ul>
            </HeaderCaption>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default WelcomeSection;
