import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import PageLayout from "../../layout/page-layout/PageLayout";

const ForgotPassword = () => {
  return (
    <PageLayout>
      <div className="nk-block nk-block-middle nk-auth-body wide-xs">
        <div className="card-preview card-bordered">
          <div className="card-inner card-inner-lg">
            <div className="nk-block-head">
              <div className="nk-block-content">
                <h5 className="nk-block-title page">Reset Password</h5>
                <div className="nk-block-des">
                  <p>
                    If you forgot your password, well, then we’ll email you
                    instructions to reset your password.
                  </p>
                </div>
              </div>
            </div>
            <form>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="form-group">
                <Button
                  color="primary"
                  size="lg"
                  className="btn-block"
                  onClick={(ev) => ev.preventDefault()}
                >
                  Send Reset Link
                </Button>
              </div>
            </form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
export default ForgotPassword;
