import React from "react";
import { Col, Container, Row } from "reactstrap";
import { SocialIcon } from "../components/icon/Icon";
import { Widget } from "../components/wdiget/Widget";
import Footer from "../layout/footer/Footer";
import { SocialIconOne } from "./footer/FooterData";

const FooterSection = (props) => {
  return (
    <Footer
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <h6 className="contacts-title">Our contacts</h6>
        <Row className="justify-content-between">
          <Col md="6" lg="4">
            <Widget className="widget-about">
              <ul>
                <li>Email: <a href="mailto:info@incwt.com">info@incwt.com</a></li>
                <li>Phone: <a href="tel:+37069033744">+370 690 33744</a></li>
                <li>Telegram: <a href="https://t.me/BitSwapBaltic" target="_blank" rel="noreferrer">https://t.me/BitSwapBaltic</a> </li>
              </ul>
            </Widget>
          </Col>
          <Col lg="6">
            <Widget>
              <ul>
                <li>Address: <a href="https://www.google.com/maps/place/Totorių+g.+30,+Vilnius,+01121+Vilniaus+m.+sav./@54.682159,25.2829986" target="_blank" rel="noreferrer">Totoriu g.30, Vilnius 01121</a></li>
                <li>Registration (Įmonės kodas): 305300467</li>
                <li>VAT (PVM mokėtojo kodas): LT100014360113</li>
              </ul>
            </Widget>
          </Col>
        </Row>
        <hr className="hr border-light mb-0 mt-n1"></hr>
        <Row className="g-3 align-items-center justify-content-md-between py-4">
          <Col md="8">
            <div className="text-base">Copyright &copy; 2024-2025 Labasnamas, UAB</div>
          </Col>
          <Col md="4" className="d-flex justify-content-md-end">
            <SocialIcon data={SocialIconOne} />
          </Col>
        </Row>
      </Container>
    </Footer>
  );
};

export default FooterSection;
