import React from "react";

function toLocaleStringSupportsOptions() {
  return !!(
    typeof Intl == "object" &&
    Intl &&
    typeof Intl.NumberFormat == "function"
  );
}

function SmartRound(value) {
  if (value > 10) {
    return parseFloat(value.toFixed(2));
  }
  return value;
}

export const NumberField = ({
  value,
  locale,
  options,
  digits,
  loading,
  ...rest
}) => {


  if (locale === undefined) {
    locale = window.navigator.language;
  }

  if (value === null || value === undefined) {
    return <></>;
  }

  let number = Number(value);
  rest.className = rest.className ? rest.className + " text-number" : "text-number";
  number = SmartRound(number);

  if (digits !== undefined) {
    options = { maximumFractionDigits: digits, minimumFractionDigits: digits };
  } else {
    number = SmartRound(number);
  }

  return (
    <span {...rest}>
      {toLocaleStringSupportsOptions()
        ? number.toLocaleString(locale, options)
        : number}
    </span>
  );
};
