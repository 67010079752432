import React, { useEffect, useState } from "react";
import { API_SERVER } from "../consts";
import { commService } from "../services/CommunicationService";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [currencies, setCurrencies] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [quotes, setQuotes] = useState(new Map());
  const [lang, setLang] = useState("en");

  const [isReady, setIsReady] = React.useState(false);
  const [isAppReady, setIsAppReady] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [quoteHub, setQuoteHub] = React.useState(null);

  const connectionStateChanged = (newReady, error) => {
    setIsReady(newReady);
    setError(error);
    if (!isReady && newReady) {
      commService.quoteHub.on("FLOW", (data) => {
        const nq = new Map(quotes);
        for (let i = 0; i < data.quotes.length; i++) {
          const quote = data.quotes[i];
          const [from, to] = quote.pairId.split("/");
          // TODO: make sure correct direction
          nq.set(`${to}/${from}`, {rate: quote.ask, change: quote.askChange, dir: quote.askDir});
          nq.set(`${from}/${to}`, {rate: quote.bid, change: quote.bidChange, dir: quote.bidDir});
        }
        setQuotes(nq);
      });
      commService.quoteHub.send("SubscribeTo", { PairIds: [`BTC/USD`], Type: "OHLC" });
    }
  };

  const startSignal = async () => {
    try {
      commService.subscribe((isReady) => connectionStateChanged(isReady));
      const { quoteHub } = await commService.ensureInitialized();
      setQuoteHub(quoteHub);

      quoteHub.onclose((error) => setError(error));
      await commService.start();
    } catch (error) {
      console.log(error.errorType);
      console.log(error.message);
      setError(error);
    }
  }

  useEffect(() => {
    startSignal();
    return () => {
      commService.quoteHub.send("UnsubscribeFromFull", { PairIds: [`BTC/USD`], Type: "OHLC" });
    }
  }, []);


  useEffect(() => {
    const fetchCurrencies = async () => {
      const [currenciesRes, pairsRes] = await Promise.all([
        fetch(`${API_SERVER}/api/currencies/`),
        fetch(`${API_SERVER}/api/pairs/`),
      ]);

      setCurrencies(await currenciesRes.json());
      setPairs(await pairsRes.json());
      setIsAppReady(true);
    };

    fetchCurrencies();
  }, []);
  return (
    <AppContext.Provider value={{ currencies, setCurrencies, lang, setLang, error, isReady, quoteHub, quotes, pairs, isAppReady }}>
      {children}
    </AppContext.Provider>
  );
};
