import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { LogoIcon } from "../components/icon/Icon";
import { Header, HeaderMain, HeaderWrap } from "../layout/header/Header";
import Menu, { MenuLink } from "../layout/menu/Menu";
import MobileMenu from "../layout/menu/MobileMenu";
import { BannerTwoData } from "./banner/BannerData";

const HeaderSection = (props) => {
  const [toggle, setToggle] = useState(false);
  const [offset, setOffset] = useState(0);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    window.scrollTo(0, 0);
    viewChange();
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  return (
    <Header
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <HeaderMain
        className={`header-main header-main-s1 is-sticky is-transparent ${
          offset > 0 ? "has-fixed" : ""
        }`}
      >
        <Container className="header-container">
          <HeaderWrap>
            <div className="header-logo">
              <MenuLink to="/#home">{LogoIcon}</MenuLink>              
            </div>
            <div className="header-toggle" onClick={() => setToggle(!toggle)}>
              <button
                className={`menu-toggler ${toggle === true ? "active" : ""}`}
                data-target="mainNav"
              >
                <em className="menu-on icon ni ni-menu"></em>
                <em className="menu-off icon ni ni-cross"></em>
              </button>
            </div>
            <nav
              className={`header-menu menu ${toggle === true ? "active" : ""} ${
                mobileView ? "mobile-menu" : ""
              }`}
            >
              {!mobileView ? (
                <Menu className="ms-lg-auto" data={BannerTwoData} />
              ) : (
                <MobileMenu data={BannerTwoData} />
              )}
              <ul className="menu-btns">
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/exchange`}
                    rel="noreferrer"
                    className="btn btn-primary btn-lg"
                  >
                    Go To Exchange
                  </Link>
                </li>
              </ul>
            </nav>
            {toggle && window.innerWidth < 992 && (
              <div
                className="header-overlay"
                onClick={() => setToggle(!toggle)}
              ></div>
            )}
          </HeaderWrap>
        </Container>
      </HeaderMain>
    </Header>
  );
};

export default HeaderSection;
